<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button
        :buttons="headerButtons"
        @onNewWorkshop="$router.push({name: 'courses.wizard', params: {type: 'workshop'}})"
      />
    </div>
    <div class="col-12">
      <workshop-datatable/>
    </div>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import WorkshopDatatable from "./datatables/WorkshopDatatable";

export default {
  name: "IndexWorkshopPage",
  components: {WorkshopDatatable, OctoHeaderButton},
  data() {
    return {
      headerButtons: [{
        label: 'add',
        onClick: 'onNewWorkshop'
      }],
    }
  }
}
</script>

<style scoped>

</style>
